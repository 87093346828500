import request from '@/utils/request'

/* 获取楼宇列表*/
export const buliding = (val) => {
    return request({
        method: 'get',
        url: 'site/buliding?school_id=' + val + '&usage=3',
    })
}

// 获取角色类型
export const roleYype = () => {
    return request({
        method: 'get',
        url: 'site/role-type'

    })
}

// 新增宿舍
export const dormitory = (data) => {
    return request({
        method: 'post',
        url: 'dormitory',
        data
    })
}
// 删除宿舍
export const delDormitory = (id) => {
    return request({
        method: 'delete',
        url: 'dormitory/' + id
    })
}

// 获取宿舍详情
export const getDormitory = (id) => {
    return request({
        method: 'get',
        url: 'dormitory/' + id
    })
}
// 修改宿舍
export const putDormitory = (id, data) => {
    return request({
        method: 'put',
        url: 'dormitory/' + id,
        data
    })
}
// 搜索居住人
export const getLive = (data) => {
    return request({
        method: 'get',
        url: 'dormitory/get-live',
        params: data
    })
}