<template>
	<div class="teacher">
		<list-template
        :loading="loading"
			:total="total"
			:table-data="tableData"
			:table-config="tableConfig"
			:search-config="searchConfig"
			:current-page="page"
			@onSearch="onSearch"
			@onReset="search = null"
			@onChangePage="changeCurrentPage"
			@onHandle="tableHandle"
			hasAdd
			@onAdd="onAdd"
		></list-template>
		<el-dialog :visible.sync="dialogVisible" width="30%">
			<span>是否删除此宿舍?</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">点错了</el-button>
				<el-button type="primary" @click="confirm">删除</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { delDormitory } from './api'
import {mapState} from "vuex";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
		return {
      loading:true,
			// 表格搜索配置
			searchConfig: [
				{
					prop: 'name',
					placeholder: '搜索宿舍名称'
				},
				{
					prop: 'is_man',
					tag: 'select',
					placeholder: '筛选是否满员',
					options: [
						{
							label: '满员',
							value: '1'
						},
						{
							label: '未满员',
							value: '0'
						}
					]
				}
			],
			// 表格配置
			tableConfig: [
				{
					prop: 'name',
					label: '宿舍名称'
				},
				{
					prop: 'buliding_name',
					label: '宿舍楼'
				},
				{
					prop: 'floor',
					label: '楼层'
				},
				{
					prop: 'is_man',
					label: '是否满员',
					render(h) {
						return h.is_man == 0 ? '未满员' : '满员'
					}
				},
				{
					prop: 'livecount',
					label: '当前居住人数'
				},
				{
					label: '操作',
					handle: true,
          width: 120,
					buttons: [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
				}
			],
			search: null,
			total: 0,
			dialogVisible: false,
			// 表格中的数据
			tableData: [],
			// 删除宿舍的id
			dormitoryId: ''
		}
	},
  computed:{
	  ...mapState(["page"])
  },
	mounted() {
    this.$store.commit("setPage",1);
		this.getData()
	},
	activated() {
		this.getData()
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
      this.$store.commit("setPage",e);
			this.getData()
		},
		/**
		 * 获取数据
		 */
		getData() {
			let { search, page } = this
      this.loading = true;
			this.$_axios.get('dormitory', { params: { ...search, page } }).then(res => {
				let { data } = res.data
				this.tableData = data
				let { total } = this.$tools.getPaginationInfo(res.headers)
				this.total = total
			}).finally(()=>this.loading = false);
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		// 搜索按钮被点击
		onSearch(val) {
      this.$store.commit("setPage",1);
			this.search = val
			this.getData()
		},
		// 表格后面操作被点击
		tableHandle(row, handle) {
      const { type } = handle
			if (type === 'edit') {
				this.$router.push({ path: './edit', query: { id: row.id } })
			} else if(type === 'delete') {
				this.dialogVisible = true
				this.dormitoryId = row.id
			}
		},
		// 弹窗确定按钮
		confirm() {
			delDormitory(this.dormitoryId)
				.then((res) => {
					// this.$message.success('删除成功')

          this.dialogVisible = false
					this.getData()
				})
				.catch(() => {
					this.dialogVisible = false
				})
		},
    test(state){
		  return new Promise((resolve, reject) => {
		    if (state)resolve("resolve")
        else reject("reject")
      })
    }
	}
}
</script>

<style scoped lang="scss">
</style>
